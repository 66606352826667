import React from 'react';
import PageTitle from '../../PageTitle/PageTitle';
import SectionContent from '../../SectionContent/SectionContent';
import './PrivacyPolicy.css';
import {
  BRAND_NAME,
  CONTACT_EMAIL,
  LEGAL_ENTITY_ADDRESS,
  LEGAL_ENTITY_NAME,
  TERMS_AND_PRIVACY_UPDATED_DATE,
} from '../../../content/constants';
import { CONTACT_PAGE_PATH } from '../../../navigation/constants';

export const COMPONENT_ID = 'privacy-policy';

function PrivacyPolicy() {
  return (
    <>
      <PageTitle title="Privacy Policy" />
      <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
        <div className="content-container-large">
          <div className="spacing-medium"></div>
          <h1>Privacy Policy</h1>
          <p className="privacy-policy-last-updated">
            Last updated {TERMS_AND_PRIVACY_UPDATED_DATE}
          </p>
          <div className="spacing-tiny"></div>
          <SectionContent title="Privacy statement">
            {BRAND_NAME} is part of {LEGAL_ENTITY_NAME}, located at{' '}
            {LEGAL_ENTITY_ADDRESS}, and is responsible for the processing of
            personal data and this privacy policy. This privacy policy explains
            how our organization uses the personal data we collect from you when
            you use {BRAND_NAME}.
          </SectionContent>
          <SectionContent title="What data do we collect?">
            {BRAND_NAME} collects the following data to optimally perform its
            services to the user:
            <ul>
              <li>
                Better the {BRAND_NAME} experience and for the user to login:
                Telephone number and/or email address;
              </li>
              <li>
                IP address solely for security purposes and to improve our
                services;
              </li>
              <li>
                Personal data that can be derived from the events that the user
                creates and/or visits;
              </li>
              <li>
                Personal data that can be derived from users activity. For
                example, adding friends, or inviting others;
              </li>
              <li>
                Personal data that the user actively provides, for example by
                providing a profile picture.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="How do we collect your data?">
            We collect data to perform our services to the user. This data is
            collected directly from the user. We collect data and process data
            when the user:
            <ul>
              <li>
                Registers with {BRAND_NAME} through an email address and/or a
                telephone number;
              </li>
              <li>
                Personal data that the user provides directly, for example by
                completing a profile;
              </li>
              <li>
                We collect necessary cookies only for the functioning of our
                product and analytical cookies to improve the user experience of{' '}
                {BRAND_NAME};
              </li>
              <li>
                The data generated by actions like adding friends, reacting to
                events and similar actions is called usage data. {BRAND_NAME}{' '}
                collects this data to improve our product and create a better
                experience for the user.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="How will we use your data?">
            {BRAND_NAME} collects the above-mentioned data so that we can:
            <li>
              Give the user the opportunity to create a profile and start the{' '}
              {BRAND_NAME} experience;
            </li>
            <li>Inform the user about changes to our services and products;</li>
            <li>
              As mentioned, the data we collect is used to provide the best{' '}
              {BRAND_NAME} experience possible for the user. This means that the
              data is used to make sure the {BRAND_NAME} functionalities work,
              for example, the possibility to host and attend events.
            </li>
          </SectionContent>
          <SectionContent title="What are your data protection rights?">
            {BRAND_NAME} would like to make sure you are fully aware of all your
            data protection rights. Every user is entitled to the following
            rights:
            <li>
              The <i>right</i> to access: you have the right to request{' '}
              {BRAND_NAME} for copies of your personal data;
            </li>
            <li>
              The <i>right</i> to rectification: you have the right to request
              that {BRAND_NAME} correct any information you believe is
              inaccurate. You also have the right to request {BRAND_NAME} to
              complete information you believe is incomplete;
            </li>
            <li>
              The <i>right</i> to erasure: you have the right to request that{' '}
              {BRAND_NAME} erases your personal data, under specific conditions;
            </li>
            <li>
              The <i>right</i> to restriction of processing: you have the right
              to request that {BRAND_NAME} restrict the processing of your
              personal data, under specific conditions;
            </li>
            <li>
              The <i>right</i> to object to the processing of your data: you
              have the right to object to {BRAND_NAME} processing your personal
              data, under specific conditions;
            </li>
            <li>
              The <i>right</i> to data portability: you have the right to
              request that {BRAND_NAME} transfers the data that we have
              collected to another organization, or directly to you, under
              specific conditions.
            </li>
            If you want to follow up on one or multiple of these requests, we
            have a month to respond to you. If you would like to exercise any of
            these rights, please contact us at{' '}
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> or reach out
            via our <a href={CONTACT_PAGE_PATH}>contact page</a>.
          </SectionContent>
          <SectionContent title={`Cookies that ${BRAND_NAME} uses`}>
            A cookie is a small text file that is stored on your device.{' '}
            {BRAND_NAME} strictly uses cookies that are necessary for the
            technical operation of our product and for the users&apos; ease of
            use. The cookies {BRAND_NAME} collects are for example used to
            ensure that the app/website works properly and your preferred
            settings are saved. {BRAND_NAME} also uses cookies to improve the
            product.
            <br />
            <br />
            As stated previously, we only collect cookies that are essential for
            the functioning of our product and service. This means that{' '}
            {BRAND_NAME} uses functional cookies. These cookies are used so that
            we recognize the user on our app/website and remember the previously
            selected preferences. These could include what language the user
            prefers and the approximate location you are at. {BRAND_NAME} also
            uses analytical cookies. They are used to understand how the user
            uses {BRAND_NAME} and to improve our product and service and thus
            better the {BRAND_NAME} experience. We do not share these analytical
            cookies with third parties.
            <br />
            <br />
            The user can decide to not allow the web browser to accept cookies.{' '}
            {BRAND_NAME} and its features may not function properly as a result,
            however. For further information on how to disable cookies or learn
            more about cookies visit{' '}
            <a href="https://allaboutcookies.org">allaboutcookies.org</a>.
          </SectionContent>
          <SectionContent title="Privacy policies of other websites">
            {BRAND_NAME} cannot function on its own. We use certain services to
            ensure that {BRAND_NAME} runs smoothly and delivers the best user
            experience. These services are to the best of our ability chosen to
            keep your personal data private. Our privacy policy only applies to{' '}
            {BRAND_NAME} and its services.
          </SectionContent>
          <SectionContent title="Privacy statement">
            We at {BRAND_NAME} value your privacy. We aim to be a company that
            you trust with your personal data because you know it is safe with
            us. We keep improving our privacy policy and keep it under regular
            review. Any updates will be placed on this web page. <br />
            <br />
            This privacy policy was last updated on{' '}
            {TERMS_AND_PRIVACY_UPDATED_DATE}
          </SectionContent>
          <SectionContent title="How to contact us">
            We at {BRAND_NAME} uphold a high standard when it comes to keeping
            the personal data safe and strictly for the purpose of improving the{' '}
            {BRAND_NAME} experience. We would like the user to help us keep this
            high standard. So if you have any questions about the privacy policy
            of {BRAND_NAME}, {BRAND_NAME} in general, the data we hold on you,
            or if you would like to exercise one of your data protection rights
            please do not hesitate to contact us at{' '}
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> or reach out
            via our <a href={CONTACT_PAGE_PATH}>contact page</a>
          </SectionContent>
          <SectionContent title="How to contact the appropriate authority">
            Should you wish to report a complaint or if you feel that{' '}
            {BRAND_NAME} has not addressed your concern in a satisfactory
            manner, you may contact the national data protection authority, the
            Dutch Data Protection Authority. <br />
            <br />
            You can do this via the following link:{' '}
            <a href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap">
              https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap
            </a>
          </SectionContent>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
