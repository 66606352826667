import React from 'react';

import './TitleSection.css';
import AppStoreLinks from '../../../AppStoreLinks/AppStoreLinks';
import { BRAND_NAME } from '../../../../content/constants';
import homeScreenshot from '../../../../assets/app-screenshots/home.png';

export const COMPONENT_ID = 'home-title-section';

function TitleSection() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="content-container-large">
        <div>
          <h1 className="text-heading-large text-bold">
            Never Miss Out Again: <br />
            Plan Activities with <br />
            Friends, Hassle-Free
          </h1>
          <div className="spacing-small"></div>
          <div className="text-large home-title-slogan">
            {BRAND_NAME}: Where Your Calendar Meets Your Social Life
          </div>
          <div className="spacing-large"></div>
          <div className="home-title-section-left-links">
            <AppStoreLinks alignment="left" />
          </div>
          <div className="home-title-section-center-links">
            <AppStoreLinks alignment="center" />
          </div>
        </div>
        <div className="home-title-section-sceenshot">
          <img
            src={homeScreenshot}
            loading="lazy"
            alt={`${BRAND_NAME} home page`}
            width="369"
          />
        </div>
        <div className="spacing-large" />
      </div>
    </div>
  );
}

export default TitleSection;
