import React from 'react';

import './Body.css';
import { Route, Routes } from 'react-router-dom';
import { BODY_PAGE_ROUTES } from '../pages/routes';

export const COMPONENT_ID = 'body';

function Body() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <Routes>
        {BODY_PAGE_ROUTES.map((props, key) => (
          <Route key={key} {...props} />
        ))}
      </Routes>
    </div>
  );
}

export default Body;
