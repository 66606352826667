import { useState } from 'react';
import {
  API_BASE_URL,
  getAuthorizationHeader,
  getCommonHeaders,
  useApiCall,
} from './api';
import urlJoin from 'url-join';
import { FormError, FormState, validate } from '../forms/forms';
import { validateEmail } from '../forms/data';

const CONTACT_US_ENDPOINT = urlJoin(API_BASE_URL, '/public-forms/contact-us');

export const useContactUsForm: () => FormState = () => {
  const [formError, setFormError] = useState<FormError>();

  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [dirtySubmit, setDirtySubmit] = useState<boolean>(false);
  const [dirtyCaptchaToken, setDirtyCaptchaToken] = useState<boolean>(false);
  const [dirtyEmail, setDirtyEmail] = useState<boolean>(false);
  const [dirtyFirstName, setDirtyFirstName] = useState<boolean>(false);
  const [dirtyLastName, setDirtyLastName] = useState<boolean>(false);
  const [dirtyMessage, setDirtyMessage] = useState<boolean>(false);

  const requireCaptchaToken = true;
  const requireEmail = true;
  const requireFirstName = true;
  const requireLastName = true;
  const requireMessage = true;

  const validatedToken = validate(captchaToken, {
    required: requireCaptchaToken,
  });
  const validatedEmail = validate(email, {
    required: requireEmail,
    validate: validateEmail,
  });
  const validatedFirstName = validate(firstName, {
    required: requireFirstName,
  });
  const validatedLastName = validate(lastName, {
    required: requireLastName,
  });
  const validatedMessage = validate(message, {
    required: requireMessage,
  });

  const [callState, execute] = useApiCall('contact-us', () =>
    fetch(CONTACT_US_ENDPOINT, {
      method: 'POST',
      headers: {
        ...getCommonHeaders(),
        ...getAuthorizationHeader(validatedToken.cleanValue),
      },
      body: JSON.stringify({
        email: validatedEmail.cleanValue,
        first_name: validatedFirstName.cleanValue,
        last_name: validatedLastName.cleanValue,
        message: validatedMessage.cleanValue,
      }),
    }),
  );

  const anyLocalError = !![
    validatedToken.validationError,
    validatedEmail.validationError,
    validatedFirstName.validationError,
    validatedLastName.validationError,
    validatedMessage.validationError,
  ].filter((value) => typeof value !== 'undefined').length;

  const callOkState = callState.response?.ok;
  const hasBadStatus = typeof callOkState !== 'undefined' && !callOkState;
  const callError =
    !callState.isLoading && (callState.hasError || hasBadStatus)
      ? FormError.UNEXPECTED
      : undefined;

  const submitForm = (): void => {
    setDirtySubmit(true);

    if (anyLocalError) {
      setFormError(FormError.INCOMPLETE);
      return;
    }

    setFormError(undefined);

    execute();
  };

  const dirtyForm =
    dirtySubmit ||
    dirtyCaptchaToken ||
    dirtyEmail ||
    dirtyFirstName ||
    dirtyLastName ||
    dirtyMessage;

  return {
    dirty: dirtyForm,

    submission: {
      submit: submitForm,
      isSuccess: !!callOkState,
      isLoading: callState.isLoading,
      error: typeof formError !== 'undefined' ? formError : callError,
      dirty: dirtySubmit,
    },

    state: {
      captchaToken: {
        value: captchaToken || '',
        dirty: dirtyCaptchaToken,
        setDirty: () => setDirtyCaptchaToken(true),
        setValue: setCaptchaToken,
        error: validatedToken.validationError,
        isRequired: requireCaptchaToken,
      },
      email: {
        value: email || '',
        dirty: dirtyEmail,
        setValue: setEmail,
        setDirty: () => setDirtyEmail(true),
        error: validatedEmail.validationError,
        isRequired: requireEmail,
      },
      firstName: {
        value: firstName || '',
        dirty: dirtyFirstName,
        setValue: setFirstName,
        setDirty: () => setDirtyFirstName(true),
        error: validatedFirstName.validationError,
        isRequired: requireFirstName,
      },
      lastName: {
        value: lastName || '',
        dirty: dirtyLastName,
        setValue: setLastName,
        setDirty: () => setDirtyLastName(true),
        error: validatedLastName.validationError,
        isRequired: requireLastName,
      },
      message: {
        value: message || '',
        dirty: dirtyMessage,
        setValue: setMessage,
        setDirty: () => setDirtyMessage(true),
        error: validatedMessage.validationError,
        isRequired: requireMessage,
      },
    },
  };
};
