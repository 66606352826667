import React, { useMemo } from 'react';
import './TextInput.css';
import { FormFieldError } from '../../forms/forms';

type InputAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type'
>;

type TextareaAttributes = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'type'
>;

export type TextInputProps = {
  id: string;
  label: string;
  required?: boolean;
  type?: 'text' | 'email' | 'password' | 'textarea';
  error?: FormFieldError;
} & InputAttributes &
  TextareaAttributes;

function TextInput(props: TextInputProps) {
  const inputType = useMemo(() => props.type || 'text', [props.type]);

  const mappedInputProps = useMemo(
    () => ({
      ...props,
      id: props.id,
      'aria-label': props.label,
      'aria-required': props.required,
      className:
        'text-input-element' + (props.className ? ` ${props.className}` : ''),
    }),
    [props],
  );

  return (
    <div className="text-input">
      <label
        htmlFor={props.id}
        className={
          'text-small text-bold' +
          (typeof props.error !== 'undefined' ? ' text-error' : '')
        }
      >
        {props.label}
        {props.required ? (
          <span className="text-input-required-label"> (required)</span>
        ) : null}
      </label>
      <div className="text-input-label-spacer" />
      {inputType === 'textarea' ? (
        <textarea {...mappedInputProps} />
      ) : (
        <input type={inputType} {...mappedInputProps} />
      )}
      <div className="spacing-tiny" />
    </div>
  );
}

export default TextInput;
