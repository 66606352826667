import React, { useCallback, useMemo } from 'react';

import './Header.css';
import logo from '../../assets/branding/logo.svg';
import { CONTACT_PAGE_PATH, INDEX_PAGE_PATH } from '../../navigation/constants';
import { BRAND_NAME } from '../../content/constants';
import { useLocation } from 'react-router-dom';

export const COMPONENT_ID = 'header';

function Header() {
  const location = useLocation();

  const getActiveClassName = useCallback(
    (path: string) => (location.pathname === path ? 'active' : ''),
    [location.pathname],
  );

  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="header-container">
        <a
          href={INDEX_PAGE_PATH}
          aria-current="page"
          className="w-inline-block"
        >
          <img
            src={logo}
            loading="lazy"
            width="150"
            alt={`${BRAND_NAME} app logo`}
          />
        </a>
        <nav role="navigation" className="navigation-links-nav">
          <div className="navigation-links-container">
            <a
              href={INDEX_PAGE_PATH}
              className={`navigation-link ${getActiveClassName(INDEX_PAGE_PATH)}`}
            >
              Home
            </a>
            <a
              href={CONTACT_PAGE_PATH}
              className={`navigation-link ${getActiveClassName(CONTACT_PAGE_PATH)}`}
            >
              Contact
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
