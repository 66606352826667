import React, { PropsWithChildren } from 'react';

import './SectionContent.css';

export const COMPONENT_TEST_ID = 'section-content';
export type SectionContentProps = {
  title: string;
};

function SectionContent(props: PropsWithChildren<SectionContentProps>) {
  const { title, children } = props;
  return (
    <div className="section-content" data-testid={COMPONENT_TEST_ID}>
      <h2 className="text-large text-bold">{title}</h2>
      <div className="text-medium">{children}</div>
      <div className="spacing-medium"></div>
    </div>
  );
}

export default SectionContent;
