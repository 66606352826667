import React from 'react';

import './Footer.css';
import {
  PRIVACY_POLICY_PAGE_PATH,
  TERMS_AND_CONDITIONS_PAGE_PATH,
} from '../../navigation/constants';
import { BRAND_NAME } from '../../content/constants';
import WhiteLogo from '../../assets/branding/logo-white.svg';

export const COMPONENT_ID = 'footer';

function Footer() {
  return (
    <footer id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="footer-container">
        <img
          src={WhiteLogo}
          loading="lazy"
          width="133"
          alt={`${BRAND_NAME} logo`}
        />
        <div className="footer-bottom text-bold">
          <p className="text-small">
            Copyright {new Date().getFullYear()} PAKT Apps BV. All rights
            reserved.
          </p>
          <div className="footer-legal-links">
            <a
              className="text-small white-link"
              href={TERMS_AND_CONDITIONS_PAGE_PATH}
            >
              Terms &amp; Conditions
            </a>
            <a
              className="text-small white-link"
              href={PRIVACY_POLICY_PAGE_PATH}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
