import React from 'react';
import SectionHeading from '../../../SectionHeading/SectionHeading';

function FedUpSection() {
  return (
    <div className="content-container-large">
      <SectionHeading
        preTitle="Features"
        title="Fed up with endless group texts and indecisive friends?"
        subtitle="Gather simplifies your social life. Plan, join, or discover activities without the back-and-forth."
      />
    </div>
  );
}

export default FedUpSection;
