import React from 'react';

import './SectionHeadingWithAppScreenshots.css';
import SectionHeading, {
  SectionHeadingProps,
} from '../SectionHeading/SectionHeading';
import AppScreenshotPair, {
  AppScreenshotPairProps,
} from '../AppScreenshotPair/AppScreenshotPair';

export const COMPONENT_TEST_ID = 'section-heading-with-app-screenshots';

type IntrisicProps = {
  inverted?: boolean;
};

type SectionHeadingWithAppScreenshotsProps = SectionHeadingProps &
  AppScreenshotPairProps &
  IntrisicProps;

function SectionHeadingWithAppScreenshots(
  props: SectionHeadingWithAppScreenshotsProps,
) {
  const { inverted } = props;
  const classList = ['section-heading-with-app-screenshots'];

  if (inverted) {
    classList.push('inverted');
  }

  return (
    <div className={classList.join(' ')} data-testid={COMPONENT_TEST_ID}>
      <div>
        <SectionHeading {...props} />
      </div>
      <div className="spacing-large" />
      <div>
        <AppScreenshotPair {...props} />
      </div>
    </div>
  );
}

export default SectionHeadingWithAppScreenshots;
