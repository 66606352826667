import React from 'react';

import './AppScreenshotPair.css';

export const COMPONENT_TEST_ID = 'app-screenshot-pair';

export type AppScreenshotPairProps = {
  leftSrc: string;
  leftAlt: string;
  rightSrc: string;
  rightAlt: string;
};

function AppScreenshotPair(props: AppScreenshotPairProps) {
  const { leftSrc, leftAlt, rightSrc, rightAlt } = props;
  return (
    <div className="app-screenshot-pair" data-testid={COMPONENT_TEST_ID}>
      <img
        width="314"
        alt={leftAlt}
        src={leftSrc}
        loading="lazy"
        className="app-screenshot-pair-left-image"
      />
      <img
        width="405"
        alt={rightAlt}
        src={rightSrc}
        loading="lazy"
        className="app-screenshot-pair-right-image"
      />
    </div>
  );
}

export default AppScreenshotPair;
