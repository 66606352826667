export enum FormError {
  INCOMPLETE,
  UNEXPECTED,
}

export type FormState = {
  dirty: boolean;
  submission: {
    submit: () => void;
    isSuccess: boolean;
    isLoading: boolean;
    error: FormError | undefined;
    dirty: boolean;
  };
  state: { [fieldName: string]: FormFieldState };
};

export enum FormFieldError {
  MISSING,
  INVALID,
}

export type FormFieldState = {
  value: string;
  dirty: boolean;
  setValue: (newValue: string) => void;
  setDirty: () => void;
  error: FormFieldError | undefined;
  isRequired: boolean;
};

export const validate: (
  userInput: string,
  options?: {
    validate?: (userInput: string) => boolean;
    required?: boolean;
    trimInput?: boolean;
  },
) => {
  cleanValue: string;
  validationError?: FormFieldError;
} = (userInput, options) => {
  const trimInput =
    typeof options?.trimInput !== 'undefined' ? options.trimInput : true;

  const valueToValidate = trimInput ? userInput.trim() : userInput;

  if (options?.required && !valueToValidate) {
    return {
      cleanValue: valueToValidate,
      validationError: FormFieldError.MISSING,
    };
  }

  if (options?.validate && !options.validate(valueToValidate)) {
    return {
      cleanValue: valueToValidate,
      validationError: FormFieldError.INVALID,
    };
  }

  return { cleanValue: valueToValidate };
};
