import React from 'react';

import './SectionHeading.css';

export const COMPONENT_TEST_ID = 'section-heading';
export type SectionHeadingProps = {
  preTitle?: string;
  title: string;
  subtitle: string;
};

function SectionHeading(props: SectionHeadingProps) {
  const { preTitle, title, subtitle } = props;
  return (
    <div className="section-heading" data-testid={COMPONENT_TEST_ID}>
      {preTitle && <h3 className="text-heading-small text-bold">{preTitle}</h3>}
      <div className="text-heading-medium text-bold">{title}</div>
      <div className="text-large">{subtitle}</div>
    </div>
  );
}

export default SectionHeading;
