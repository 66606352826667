export const BRAND_NAME = 'Gather';
export const LEGAL_ENTITY_NAME = 'PAKT Apps B.V.';
export const LEGAL_ENTITY_ADDRESS = 'Woubruggestraat 8, 1059 VR Amsterdam';
export const CONTACT_EMAIL = 'contact@pakthq.com';

export const MAIN_SITE_DOMAIN = 'https://www.gather-app.com';

// TODO This should be localised correctly
export const TERMS_AND_PRIVACY_UPDATED_DATE = new Date(
  2024,
  8,
  25,
  10,
  15,
).toLocaleDateString();
