import React from 'react';

import './Home.css';
import TitleSection from './TitleSection/TitleSection';
import BottomSection from './BottomSection/BottomSection';
import UpcomingFeaturesSection from './UpcomingFeaturesSection/UpcomingFeaturesSection';
import FedUpSection from './FedUpSection/FedUpSection';
import SocialDiscoverySection from './SocialDiscoverySection/SocialDiscoverySection';
import CreateActivitiesSection from './CreateActivitiesSection/CreateActivitiesSection';
import { BRAND_NAME } from '../../../content/constants';
import PageTitle from '../../PageTitle/PageTitle';

export const COMPONENT_ID = 'home';

function Home() {
  return (
    <>
      <PageTitle title={BRAND_NAME} />
      <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
        <TitleSection />
        <div className="spacing-xlarge" />
        <FedUpSection />
        <div className="spacing-xlarge" />
        <SocialDiscoverySection />
        <div className="spacing-xlarge" />
        <CreateActivitiesSection />
        <div className="spacing-xlarge" />
        <UpcomingFeaturesSection />
        <div className="spacing-xlarge" />
        <BottomSection />
      </div>
    </>
  );
}

export default Home;
