import React from 'react';

import SectionHeadingWithAppScreenshots from '../../../SectionHeadingWithAppScreenshots/SectionHeadingWithAppScreenshots';
import { BRAND_NAME } from '../../../../content/constants';
import profileAppScreenshot from '../../../../assets/app-screenshots/profile.png';
import activityFeedAppScreenshot from '../../../../assets/app-screenshots/activity-feed.png';

export const COMPONENT_ID = 'social-discovery-section';

function SocialDiscoverySection() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="content-container-large">
        <SectionHeadingWithAppScreenshots
          preTitle="Features"
          title="Social Discovery, Simplified"
          subtitle="See what your friends are up to and join in on the fun, all in one easy feed."
          leftAlt={`${BRAND_NAME} profile`}
          leftSrc={profileAppScreenshot}
          rightAlt={`${BRAND_NAME} activity feed`}
          rightSrc={activityFeedAppScreenshot}
        />
      </div>
    </div>
  );
}

export default SocialDiscoverySection;
