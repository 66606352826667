import urlJoin from 'url-join';
import env from '../.env.json';
import { logger } from './../logging/sentry-logger';
import { useState } from 'react';
import { v4 as uuidv4, validate } from 'uuid';

export const API_BASE_PATH = '/api/v0';
export const API_BASE_URL = urlJoin(
  env.REACT_APP_API_HOST || '',
  API_BASE_PATH,
);

export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';
export const AUTHORIZATION_HEADER = 'Authorization';
export const CONTENT_TYPE_HEADER = 'Content-Type';
export const X_VISITOR_ID_HEADER = 'X-Visitor-Id';

export const getCommonHeaders = () => ({
  [ACCEPT_LANGUAGE_HEADER]: 'en', // TODO set language based on settings
  [CONTENT_TYPE_HEADER]: 'application/json',
  [X_VISITOR_ID_HEADER]: getDeviceId(),
});

export const getAuthorizationHeader = (token: string) => ({
  [AUTHORIZATION_HEADER]: `Bearer ${token}`,
});

type ApiCallState = {
  response: Response | undefined;
  isLoading: boolean;
  hasError: boolean;
};

export const useApiCall: (
  purpose: string,
  makeCall: () => Promise<Response>,
) => [ApiCallState, () => unknown] = (purpose, makeCall) => {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [response, setResponse] = useState<Response>();

  const execute = () => {
    if (isLoading) {
      return;
    }

    setResponse(undefined);
    setLoading(true);
    setHasError(false);

    makeCall()
      .then(setResponse)
      .catch((e) => {
        setHasError(true);
        logger.error(
          `An unexpected exception occurred when making the ${purpose} API call`,
          e,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const state = {
    response,
    isLoading,
    hasError,
  };

  return [state, execute];
};

const DEVICE_ID_STORAGE_KEY = 'device-id';
const getDeviceId = () => {
  const storedValue = window.localStorage.getItem(DEVICE_ID_STORAGE_KEY);

  if (storedValue && validate(storedValue)) {
    return storedValue;
  }

  const newValue = uuidv4();
  window.localStorage.setItem(DEVICE_ID_STORAGE_KEY, newValue);
  return newValue;
};
