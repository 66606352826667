import React from 'react';

import './BottomSection.css';
import AppStoreLinks from '../../../AppStoreLinks/AppStoreLinks';

export const COMPONENT_ID = 'home-bottom-section';

function BottomSection() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="content-container-large">
        <div id="home-bottom-section-background">
          <h2 className="text-heading-medium text-bold">
            Ready for a stress-free social life?
          </h2>
          <div className="text-large">
            Download Gather now and start making plans you&apos;ll actually
            keep.
          </div>
          <div className="spacing-medium"></div>
          <AppStoreLinks alignment="center" />
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
