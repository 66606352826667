import React from 'react';

import {
  CONTACT_PAGE_PATH,
  INDEX_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
  TERMS_AND_CONDITIONS_PAGE_PATH,
} from '../../navigation/constants';

import Home from './Home/Home';
import Contact from './Contact/Contact';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import { RouteProps } from 'react-router-dom';

export const BODY_PAGE_ROUTES: RouteProps[] = [
  {
    path: INDEX_PAGE_PATH,
    element: <Home />,
  },
  {
    path: CONTACT_PAGE_PATH,
    element: <Contact />,
  },
  {
    path: TERMS_AND_CONDITIONS_PAGE_PATH,
    element: <TermsAndConditions />,
  },
  {
    path: PRIVACY_POLICY_PAGE_PATH,
    element: <PrivacyPolicy />,
  },
];
