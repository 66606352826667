import React from 'react';
import env from '../../../.env.json';
import PageTitle from '../../PageTitle/PageTitle';
import TextInput from '../../TextInput/TextInput';
import './Contact.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useContactUsForm } from '../../../api/contact-us';
import { FormError } from '../../../forms/forms';

export const COMPONENT_ID = 'contact';

function Contact() {
  const { submission, state } = useContactUsForm();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submission.submit();
  };

  const isDone = submission.isSuccess;
  const canEdit = !isDone && !submission.isLoading;

  return (
    <>
      <PageTitle title="Contact Us" />
      <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
        <div className="contact-form-spacer" />
        <div className="content-container-large">
          <div className="contact-form-container">
            <div className="contact-form-title">
              <h1>Contact us</h1>
            </div>

            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="contact-form-name">
                <TextInput
                  id="contact-first-name"
                  label="First name"
                  required={state.firstName.isRequired}
                  disabled={!canEdit}
                  value={state.firstName.value}
                  onChange={(e) => state.firstName.setValue(e.target.value)}
                  onBlur={state.firstName.setDirty}
                  error={
                    submission.dirty || state.firstName.dirty
                      ? state.firstName.error
                      : undefined
                  }
                />
                <TextInput
                  id="contact-last-name"
                  label="Last name"
                  required={state.lastName.isRequired}
                  disabled={!canEdit}
                  value={state.lastName.value}
                  onChange={(e) => state.lastName.setValue(e.target.value)}
                  onBlur={state.lastName.setDirty}
                  error={
                    submission.dirty || state.lastName.dirty
                      ? state.lastName.error
                      : undefined
                  }
                />
              </div>
              <div>
                <TextInput
                  id="contact-email"
                  type="email"
                  label="Email"
                  required={state.email.isRequired}
                  disabled={!canEdit}
                  value={state.email.value}
                  onChange={(e) => state.email.setValue(e.target.value)}
                  onBlur={state.email.setDirty}
                  error={
                    submission.dirty || state.email.dirty
                      ? state.email.error
                      : undefined
                  }
                />
              </div>
              <div>
                <TextInput
                  id="contact-message"
                  type="textarea"
                  label="Message"
                  required={state.message.isRequired}
                  disabled={!canEdit}
                  value={state.message.value}
                  onChange={(e) => state.message.setValue(e.target.value)}
                  onBlur={state.message.setDirty}
                  error={
                    submission.dirty || state.message.dirty
                      ? state.message.error
                      : undefined
                  }
                />
              </div>
              <div className="contact-form-submission">
                <div className="spacing-medium"></div>

                {!isDone && (
                  <>
                    <HCaptcha
                      sitekey={env.REACT_APP_H_CAPTCHA_SITE_KEY || ''}
                      onVerify={(token) => state.captchaToken.setValue(token)}
                    />
                    <div className="spacing-small"></div>
                  </>
                )}

                {submission.dirty &&
                submission.error === FormError.INCOMPLETE ? (
                  <>
                    <div className="text-error">
                      Please fill in all required fields
                    </div>
                    <div className="spacing-small"></div>
                  </>
                ) : null}

                {submission.dirty &&
                submission.error === FormError.UNEXPECTED ? (
                  <>
                    <div className="text-error">
                      Sorry, an unxepected error has occurred. <br />
                      Please try again later.
                    </div>
                    <div className="spacing-small"></div>
                  </>
                ) : null}

                {isDone && (
                  <>
                    <div className="text-success">
                      Your message has been sent successfully! <br />
                      We will get back to you as soon as possible.
                    </div>
                    <div className="spacing-small"></div>
                  </>
                )}

                {!isDone && (
                  <button
                    type="submit"
                    disabled={!canEdit}
                    className="button-submit text-medium"
                  >
                    {submission.isLoading ? 'Sending...' : 'Send Message'}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="contact-form-spacer" />
      </div>
    </>
  );
}

export default Contact;
