import React from 'react';

import SectionHeadingWithAppScreenshots from '../../../SectionHeadingWithAppScreenshots/SectionHeadingWithAppScreenshots';
import { BRAND_NAME } from '../../../../content/constants';
import activityAppScreenshot from '../../../../assets/app-screenshots/activity-page.png';
import createActivityAppScreenshot from '../../../../assets/app-screenshots/create-activity.png';

export const COMPONENT_ID = 'create-activities-section';

function CreateActivitiesSection() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="content-container-large">
        <SectionHeadingWithAppScreenshots
          inverted={true}
          preTitle="Features"
          title="Create your own activities"
          subtitle="Got a cool idea? Create an activity and invite your circle or open it up to their friends as well."
          leftAlt={`${BRAND_NAME} activity`}
          leftSrc={activityAppScreenshot}
          rightAlt={`Create activity on ${BRAND_NAME}`}
          rightSrc={createActivityAppScreenshot}
        />
      </div>
    </div>
  );
}

export default CreateActivitiesSection;
