import React from 'react';

import './TermsAndConditions.css';
import PageTitle from '../../PageTitle/PageTitle';
import SectionContent from '../../SectionContent/SectionContent';
import {
  CONTACT_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
} from '../../../navigation/constants';
import {
  BRAND_NAME,
  CONTACT_EMAIL,
  LEGAL_ENTITY_ADDRESS,
  LEGAL_ENTITY_NAME,
  TERMS_AND_PRIVACY_UPDATED_DATE,
} from '../../../content/constants';

export const COMPONENT_ID = 'terms-and-conditions';

function TermsAndConditions() {
  return (
    <>
      <PageTitle title="Terms and Conditions" />
      <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
        <div className="content-container-large">
          <div className="spacing-medium"></div>
          <h1>Terms and Conditions</h1>
          <p className="terms-and-conditions-last-updated">
            Last updated {TERMS_AND_PRIVACY_UPDATED_DATE}
          </p>
          <div className="spacing-tiny"></div>
          <SectionContent title="Agreement to terms">
            These terms and conditions (&quot;Terms&quot;) constitute a legally
            binding agreement between you, whether personally or on behalf of an
            entity (&quot;you&quot;) and {LEGAL_ENTITY_NAME} (&quot;we&quot;,
            &quot;us&quot; or &quot;our&quot;). {LEGAL_ENTITY_NAME} is located
            at ${LEGAL_ENTITY_ADDRESS}. This legally binding agreement concerns
            your access to and use of the {BRAND_NAME} product. By accessing or
            using our (mobile) applications, websites or any other {BRAND_NAME}{' '}
            service (together, the &quot;Service&quot;) made available by{' '}
            {BRAND_NAME}, however accessed, you agree that you have read,
            understood and agree to be bound by these terms. The service is
            owned or controlled by {BRAND_NAME}. These terms affect your legal
            rights and obligations. If you do not agree with all these terms,
            then you are expressly prohibited from using {BRAND_NAME} services
            and you must discontinue use immediately.
          </SectionContent>
          <SectionContent title="General conditions">
            <ul>
              <li>
                It is your responsibility to periodically review these terms and
                conditions to stay informed of updates. You will be subject to,
                and will be deemed to have been made aware of and accepted the
                changes in a revised version of the terms when continued use of
                the service after the date of such a change are posted.
              </li>
              <li>
                We will alert you of this change by the change of date in the
                &quot;last updated&quot; date of these terms and you waive any
                right to receive specific notice of each such change.
              </li>
              <li>
                We reserve the right to modify or terminate the service or your
                access to the service for any reason, without notice, at any
                time, and without liability to you.
              </li>
              <li>You must be at least 18 years old to use the service.</li>
            </ul>
          </SectionContent>
          <SectionContent title="User registration and account security">
            <ul>
              <li>
                When registering with the service, you agree to keep your
                password confidential and will be responsible for all use of
                your account and password. We reserve the right to remove,
                reclaim, or change a username you select if we determine, in our
                sole discretion, that such username is inappropriate, obscene or
                otherwise objectionable
              </li>
              <li>
                You represent that all information you provide or provided to{' '}
                {BRAND_NAME} upon registration and at all other times will be
                true, accurate, current and complete and you agree to update
                your information as necessary to maintain its truth and accuracy
              </li>
              <li>
                You are responsible for any activity that occurs through your
                account and you agree you will not sell, transfer, license or
                assign your account, username, or any other account rights. With
                the exception of people or businesses that are expressly
                authorized to create accounts on behalf of their employees or
                clients, {BRAND_NAME} prohibits the creation of and you agree
                that you will not create an account for anyone other than
                yourself
              </li>
              <li>
                You will not access the Service through automated or non-human
                means, whether through a bot, script, or otherwise
              </li>
              <li>
                You will not use the service for any illegal or unauthorized
                purpose
              </li>
              <li>
                Your use of the site will not violate any applicable law or
                regulation
              </li>
              <li>
                If you provide untrue, inaccurate, not current, or incomplete
                information, we reserve the right to suspend or terminate your
                account and refuse any and all current or future use of the
                service (or any portion thereof)
              </li>
              <li>
                You agree that you will not solicit, collect or use the login
                credentials of other {BRAND_NAME} users{' '}
              </li>
              <li>
                You must not attempt to restrict another user from using or
                enjoying the service and you must not encourage or facilitate
                violations of these terms or any other {BRAND_NAME} terms
              </li>
              <li>
                You are solely responsible for your conduct and any data, text,
                files, information, usernames, images, graphics, photos,
                profiles, works of authorship, applications, links and other
                content or materials (collectively &quot;Content&quot;) that you
                submit, post or display on or via the service
              </li>
              <li>
                Violation of these Terms may, in the sole discretion of{' '}
                {BRAND_NAME}, result in termination of your {BRAND_NAME}{' '}
                account. You understand and agree that {BRAND_NAME} cannot and
                will not be responsible for the Content posted on the Service
                and you use the Service at your own risk. If you violate the
                letter or spirit of these Terms, or otherwise create risk or
                possible legal exposure for {BRAND_NAME}, we can stop providing
                all or part of the Service to you
              </li>
              <li>
                We reserve the right to force forfeiture of any username for any
                reason
              </li>
              <li>
                Upon termination, all rights granted to you in these terms will
                immediately cease
              </li>
              <li>
                When you sign up on behalf of an organization (&quot;instructing
                party&quot; and or &quot;organization&quot;), you acknowledge
                that you are competent and authorized to that effect. You
                confirm that you are authorized to develop business activities
                in your country.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Safety">
            <ul>
              <li>
                You must not create or submit unwanted email, comments or other
                forms of commercial or harassing communications (spam) to any{' '}
                {BRAND_NAME} users
              </li>
              <li>
                You must not defame, stalk, bully, abuse, harass, threaten,
                impersonate, discriminate or intimidate people or entities and
                you must not post private or confidential information via the
                Service
              </li>
              <li>
                You may not post violent, nude, partially nude, discriminatory,
                unlawful, infringing, hateful, pornographic or sexually
                suggestive content via the Service
              </li>
              <li>
                You may not use the service for any illegal or unauthorized
                purpose. You agree to comply with all laws, rules and
                regulations (for example state, local and provincial) applicable
                to your use of the service, including but not limited to,
                copyright laws
              </li>
              <li>
                You must not interfere or disrupt the service or servers or
                networks connected to the service, including by transmitting any
                worms, viruses, spyware, malware or any other code of a
                destructive or disruptive nature. You may not inject content or
                code or otherwise alter or interfere with the way any{' '}
                {BRAND_NAME} page is rendered or displayed in a user&apos;s
                browser or device
              </li>
              <li>
                You must not change, modify, adapt or alter the Service or
                change, modify or alter another website so as to falsely imply
                that it is associated with the Service or {BRAND_NAME}
              </li>
              <li>
                We may, but have no obligation to, remove, edit, block, and/or
                monitor Content or accounts containing Content that we determine
                in our sole discretion violates these Terms. {BRAND_NAME}{' '}
                reserves the right to remove any Content from the Service for
                any reason, without prior notice. Content removed from the
                Service may continue to be stored by {BRAND_NAME}, including,
                without limitation, in order to comply with certain legal
                obligations, but may not be retrievable without a valid court
                order. Consequently, {BRAND_NAME} encourages you to maintain
                your own backup of your Content. {BRAND_NAME} is not a backup
                service and you agree that you will not rely on the Service for
                the purposes of Content backup or storage. {BRAND_NAME} will not
                be liable to you for any modification, suspension, or
                discontinuation of the Services, or the loss of any Content. You
                also acknowledge that the Internet may be subject to breaches of
                security and that the submission of Content or other information
                may not be secure
              </li>
              <li>
                You agree that {BRAND_NAME} is not responsible for, and does not
                endorse, content posted within the Service. {BRAND_NAME} does
                not have any obligations to pre-screen, monitor, edit, or remove
                any content. If your content violates these terms, you may have
                bear legal responsibility for that content
              </li>
              <li>
                You agree that you are responsible for all data charges you
                incur through use of the service
              </li>
              <li>
                We prohibit crawling, scraping, caching or otherwise accessing
                any content on the Service via automated means (except as may be
                the result of standard search engine protocols or technologies
                used by a search engine with the express consent of {BRAND_NAME}
                ).
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Prohibited activities">
            <ul>
              <li>
                You may not access or use the Service for any purpose other than
                for which we make the Service available. The Service may not be
                used in connection with any commercial endeavors except those
                that are specifically endorsed or approved by us
              </li>
              <li>
                As a user, you agree not to make any unauthorized use of the
                Service, including collecting usernames and/or email addresses
                of users by electronic or other means for the purpose of sending
                unsolicited email or creating user accounts by automated means
                or under false pretences
              </li>
              <li>
                As a user, you agree to not use the Service to advertise or
                offer to sell goods and services. As a user, you also agree to
                not circumvent, disable, or otherwise interfere with
                security-related features of the Service, including features
                that prevent or restrict the use or copying of any Content or
                enforce limitations on the use of the Service and/or Content
                contained therein
              </li>
              <li>
                As a user, you agree to not attempt to impersonate another user
                or person or use the username of another user, sell or otherwise
                transfer your profile or use any information obtained from the
                Service in order to harass, abuse or harm another person
              </li>
              <li>
                As a user, you agree to not disparage, tarnish or otherwise
                harm, in our opinion, us and/or the Service. You may also not
                use the Service in a manner inconsistent with any applicable
                laws or regulations
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Intellectual property rights">
            <ul>
              <li>
                The Service is owned or licensed by {LEGAL_ENTITY_NAME} (&quot;
                {BRAND_NAME} Content&quot;). Unless otherwise indicated, the
                service is our propriety property and all source code,
                databases, functionality, software, website designs, audio,
                video, text, photographs, and graphics on the site
                (collectively, the &quot;content&quot;) and the trademarks,
                service marks, and logos contained (the &quot;logo&apos;s&quot;)
                are owned by us or licensed to us, and are protected by
                copyright and trademark laws. {BRAND_NAME} owns and retains all
                rights in the {BRAND_NAME} Content and the Service. You will not
                remove, alter or conceal any copyright, trademark or other
                proprietary rights notices incorporated in or accompanying the{' '}
                {BRAND_NAME} Content and you will not reproduce, modify, adapt,
                prepare derivative works based on, perform, display, publish,
                distribute, transmit, broadcast, sell, license or otherwise
                exploit the {BRAND_NAME} Content
              </li>
              <li>
                {LEGAL_ENTITY_NAME} hereby grants to you a non-transferable
                license to use the Service. You may not distribute or make the
                Service available over a network where it could be used by
                multiple devices at the same time. You may not rent, lease,
                lend, sell, redistribute or sublicense the Service. You may not
                copy (except as expressly permitted by this license), decompile,
                reverse engineer, disassemble, attempt to derive the source code
                of, modify, or create derivative works of the Service, any
                updates, or any part thereof (except as and only to the extent
                any foregoing restriction is prohibited by applicable law or to
                the extent as may be permitted by the licensing terms governing
                the use of any open-sourced components included with the
                Service). Any attempt to do so is a violation of the rights of
                {LEGAL_ENTITY_NAME}. If you breach this restriction, you may be
                subject to prosecution and damages. The Terms will govern any
                upgrades provided by {BRAND_NAME} that replace and/or supplement
                the original Service, unless such upgrade is accompanied by
                updated terms in which case these terms will govern
              </li>
              <li>
                {BRAND_NAME} does not claim ownership of any Content that you
                post on or through the Service. Instead, you hereby grant to{' '}
                {BRAND_NAME} a non-exclusive, royalty-free, transferable,
                sub-licensable, worldwide license to use the Content that you
                post on or through the Service, subject to the privacy policy of{' '}
                {BRAND_NAME}
              </li>
              <li>
                You represent and warrant that: (i) you own the Content posted
                by you on or through the Service or otherwise have the right to
                grant the rights and licenses set forth in these Terms; (ii) the
                posting and use of your Content on or through the Service does
                not violate, misappropriate or infringe on the rights of any
                third party, including, without limitation, privacy rights,
                publicity rights, copyrights, trademark and/or other
                intellectual property rights; (iii) you agree to pay for all
                royalties, fees, and any other monies owed by reason of Content
                you post on or through the Service; and (iv) you have the legal
                right and capacity to enter into these Terms in your
                jurisdiction
              </li>
              <li>
                The {BRAND_NAME} name and logo are trademarks of {BRAND_NAME},
                and may not be copied, imitated or used, in whole or in part,
                without the prior written permission of {BRAND_NAME}. In
                addition, all page headers, custom graphics, button icons and
                scripts are proprietary rights of {BRAND_NAME}, and may not be
                copied, imitated or used, in whole or in part, without prior
                written permission from {BRAND_NAME}
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Organizations and ticketing">
            <ul>
              <li>
                Organization or instructing party is defined as the party that
                acts on behalf and in the capacity of an occupation, company or
                business in using the {BRAND_NAME} services
              </li>
              <li>
                When you sign up on behalf of an organization, you acknowledge
                that you are competent and authorized to that effect. You
                confirm that you are authorized to develop business activities
                in your country
              </li>
              <li>
                {BRAND_NAME} shall only be liable for its own acts or omissions
                and not for acts or omissions of third parties. This exclusion
                expressly applies to acts or omissions for events or activities
                originating outside the services of {BRAND_NAME} (such as
                internet disturbances or malfunctions in third party systems),
                except in case such events were caused by the intent or gross
                negligence of {BRAND_NAME}
              </li>
              <li>
                {BRAND_NAME} will perform commercially responsible efforts to
                enable the instructing party/organization to sell tickets for
                events that it hosts. The transaction takes place between
                organization and user, {BRAND_NAME} is expressly not a party to
                this transaction.
              </li>
              <li>
                For tickets bought for events that were hosted through{' '}
                {BRAND_NAME}, the instructing party/organization should be
                approached for any refunds. As stated, {BRAND_NAME} is not a
                party in the transaction that takes place between organization
                and user, it is therefore not liable for any refunds, changes,
                or any other damages
              </li>
              <li>
                {BRAND_NAME} has the right in case of malfunctions, maintenance
                or security incidents to (partially) suspend these commercially
                responsible efforts. {BRAND_NAME} will, if possible, inform the
                organization of the (partial) suspension of these efforts.{' '}
                {BRAND_NAME} will consider fraud prevention and detection or
                interests of third parties when making the decision to inform
                the organization
              </li>
              <li>
                {BRAND_NAME} has the right to, without prior notice and without
                any obligation to make good on any possible damages, cease any
                efforts if {BRAND_NAME} believes that a party is not fulfilling
                its obligations stipulated in the terms and conditions, a
                contract, or any other agreement
              </li>
              <li>
                The organization will not use the {BRAND_NAME} services to the
                extent that it&apos;s used for:
              </li>
              <li>Illegal activities</li>
              <li>Promoting illegal activities</li>
              <li>
                Activities that could infringe on the rights of third parties
              </li>
              <li>
                Activities that could harm the reputation of {BRAND_NAME} or the
                services of {BRAND_NAME}
              </li>
              <li>
                {BRAND_NAME} is not a party between agreements between the
                organization and the user, third parties or others. {BRAND_NAME}{' '}
                is not liable to the organization for a legal act of the user or
                third parties. By agreeing to the terms and conditions, the
                organization exempts {BRAND_NAME} for any claim from the user,
                third parties or others that relate to the agreement between
                organization and user
              </li>
              <li>
                Without prejudice to the foregoing regarding liability, and
                unless caused by direct effect due to gross blame or negligence,{' '}
                {BRAND_NAME} is not liable for damages that are related to:
              </li>
              <li>The organization not upholding agreements or contracts</li>
              <li>
                Measures taken by domestic, foreign or international governments
              </li>
              <li>International conflicts</li>
              <li>Violent or armed actions</li>
              <li>Measures by competent authorities</li>
              <li>Actions resulting from a boycott</li>
              <li>
                Workers irregularities at third parties or within {BRAND_NAME}
              </li>
              <li>
                Failures or damages in the electric networks, telecommunications
                or other such networks
              </li>
              <li>
                Shortcoming by third parties that {BRAND_NAME} involves to
                uphold the agreements and/or terms and conditions
              </li>
              <li>Cybercrime.</li>
            </ul>
          </SectionContent>
          <SectionContent title="User generated contributions">
            <ul>
              <li>
                The Service may invite you to contribute to, make content,
                organise events, make friends and may encourage the use of other
                functionalities. The Service may provide you with the
                opportunity to create, submit, post, display or send other
                functionalities and materials to us or post these on the
                Service. These materials include, but are not limited to text,
                writing, video, audio and photographs and other materials
                (collectively, “Contributions”). These contributions can be
                viewable by other users of the Service. As such, Contributions
                you transmit may be treated as non-confidential and
                non-proprietary. When you create or make available any
                Contributions, you thereby agree that:{' '}
              </li>
              <li>
                The creation, distribution, transmission, public display and the
                accessing, downloading, or copying of your Contributions do not
                and will not infringe to the right of third parties{' '}
              </li>
              <li>
                Your Contributions are not false, inaccurate or misleading
              </li>
              <li>
                Your Contributions are not obscene, lewd, lascivious, filthy,
                violent or harassing. They are furthermore not libellous,
                slanderous, or otherwise objectionable in our opinion. The
                Contributions do not ridicule, mock, disparage, intimidate, or
                abuse anyone
              </li>
              <li>
                The Contributions do not violate any applicable law, regulation
                or rule. They do not violate the privacy or publicity rights of
                any third party
              </li>
              <li>
                Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference, or physical handicap.
              </li>
              <li>
                Any use of the Service in violation with the above-mentioned
                points, these Terms and Conditions or other agreements, may
                result in, among other things, termination or suspension of your
                rights to use the Service.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Third-party content">
            <ul>
              <li>
                The Service may contain links to other websites or services
                (“Third Party Content”) as well as articles, photographs, text,
                graphics, pictures, designs, music, sound, video, information,
                applications, software and other content belonging to or
                originating from Third Party Content. Such Third Party Content
                is not investigated, monitored or checked by us. We are not
                responsible for any Third Party Content accessible on or by the
                use of our Service
              </li>
              <li>
                If you leave the Service and access Third Party Content, you do
                so at your own risk, and you should be aware that these Terms
                and Conditions do no longer apply
              </li>
              <li>
                When accessing Third Party Content, you should review the
                applicable terms and policies including privacy and data
                gathering practices. Any purchase you make through Third Party
                Content will be through other websites and services from other
                companies. We therefore expressly take no responsibility
                whatsoever in relation to such purchases which are exclusively
                between you and the applicable third party.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Privacy policy">
            We at {BRAND_NAME} care about data privacy and security. To read all
            about it, please review our{' '}
            <a href={PRIVACY_POLICY_PAGE_PATH}>Privacy Policy</a>. The{' '}
            {BRAND_NAME} Services are hosted in the European Union.
          </SectionContent>
          <SectionContent title="Modifications and interruptions">
            <ul>
              <li>
                We at {BRAND_NAME}, reserve the right to change, modify, or
                remove the contents of the Services at any time or for any
                reason at our sole discretion without notice. However, we do not
                have an obligation to update any or all information within our
                Service. We furthermore reserve the right to modify, change or
                discontinue all or part of the Service without any notice at any
                time
              </li>
              <li>
                We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Service. We furthermore cannot guarantee the Service will be
                available at all times. We may experience hardware, software, or
                other problems or need to perform maintenance related to the
                Service, with the possible result of any interruptions, delays,
                or errors
              </li>
              <li>
                We reserve the right, for the {BRAND_NAME} services, to change,
                revise, update, suspend, discontinue, or otherwise modify the
                Service at any time or for any reason without notice to you. By
                using our services you agree that we have no liability towards
                you for any loss, damage, or other inconvenience caused by the
                fact that you cannot access our Service during any downtime of
                our Service
              </li>
              <li>
                Nothing in these Terms and Conditions will be interpreted and/or
                construed to obligate us to maintain and support the Service or
                to supply any corrections, updates or other measures in
                connection to this.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Governing law">
            These Terms and Conditions and your use of the Services are governed
            by and made in accordance with the laws of the Netherlands.
          </SectionContent>
          <SectionContent title="Corrections">
            There may be information on our Service that might contain
            typographical errors, inaccuracies, or omissions that may relate to
            the Service, including descriptions, pricing, agreements and various
            other information. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update the information
            on the Services at any time, without prior notice to you.
          </SectionContent>
          <SectionContent title="No warranty">
            <ul>
              <li>
                The Service, including, without limitation, {BRAND_NAME}{' '}
                Content, is provided on an &quot;as is&quot;, &quot;as
                available&quot; and &quot;with all faults&quot; basis. To the
                fullest extent permissible by law, neither {BRAND_NAME} nor any
                of its directors, employees, managers, officers, partners,
                affiliates or agents (collectively, the &quot;{BRAND_NAME}
                &quot;) make any representations or warranties or endorsements
                of any kind whatsoever, express or implied, as to: (a) the
                Service; (b) the {BRAND_NAME} Content; (c) user content; or (d)
                security associated with the transmission of information to{' '}
                {BRAND_NAME} or via the Service. In addition, the {BRAND_NAME}{' '}
                hereby disclaim all warranties, express or implied, including,
                but not limited to, the warranties of merchantability, fitness
                for a particular purpose, non-infringement, title, custom,
                trade, quiet enjoyment, system integration and freedom from
                computer virus
              </li>
              <li>
                {BRAND_NAME} does not represent or warrant that the Service will
                be error-free or uninterrupted; that defects will be corrected;
                or that the Service or the server that makes the Service
                available is free from any harmful components, including,
                without limitation, viruses. {BRAND_NAME} do not make any
                representations or warranties that the information (including
                any instructions) on the Service is accurate, complete, or
                useful
              </li>
              <li>
                Although it is the intention of {BRAND_NAME} for the Service to
                be available as much as possible, there will be occasions when
                the Service may be interrupted, including, without limitation,
                for scheduled maintenance or upgrades, for emergency repairs, or
                due to failure of telecommunications links and/or equipment
              </li>
              <li>
                You acknowledge that your use of the Service is at your sole
                risk. {BRAND_NAME} does not warrant that your use of the Service
                is lawful in any particular jurisdiction, and {BRAND_NAME}{' '}
                specifically disclaim such warranties. Some jurisdictions limit
                or do not allow the disclaimer of implied or other warranties so
                the above disclaimer may not apply to you to the extent such
                jurisdiction&apos;s law is applicable to you and these Terms
              </li>
              <li>
                By accessing or using the Service you represent and warrant that
                your activities are lawful in every jurisdiction where you
                access or use the Service
              </li>
              <li>
                {BRAND_NAME} does not endorse Content and specifically disclaim
                any responsibility or liability to any person or entity for any
                loss, damage (whether actual, consequential, punitive or
                otherwise), injury, claim, liability or other cause of any kind
                or character based upon or resulting from any content.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Limitation of liability">
            To the extent not prohibited by law, in no event shall the{' '}
            {BRAND_NAME} be liable to you for any loss or damages of any kind
            (including, without limitation, for any direct, indirect, economic,
            exemplary, special, punitive, incidental or consequential losses or
            damages) that are directly or indirectly related to:
            <ul>
              <li>The Service;</li>
              <li>The {BRAND_NAME} Content;</li>
              <li>User content;</li>
              <li>
                Your use of, inability to use, or the performance of the
                Service;
              </li>
              <li>
                Any action taken in connection with an investigation by the
                {BRAND_NAME} or law enforcement authorities regarding you or any
                other party’s use of the Service;
              </li>
              <li>
                Any action taken in connection with copyright or other
                intellectual property owners;
              </li>
              <li>
                Any errors or omissions in the operations of the Service; or
              </li>
              <li>
                any damage to any user&apos;s computer, mobile device, or other
                equipment or technology including, without limitation, damage
                from any security breach or from any virus, bugs, tampering,
                fraud, error, omission, interruption, defect, delay in operation
                or transmission, computer line or network failure or any other
                technical or other malfunction, including, without limitation,
                damages for lost profits, loss of goodwill, loss of data, work
                stoppage, the accuracy of results, or computer failure or
                malfunction, even if foreseeable or even if the {BRAND_NAME}{' '}
                have been advised of or should have known of the possibility of
                such damages, whether in an action of contract, negligence,
                strict liability or tort (including, without limitation, whether
                caused in whole or in part by negligence, acts of God,
                telecommunications failure, or theft or destruction of the
                service)
              </li>
              <li>
                In no event will the {BRAND_NAME} be liable to you or anyone
                else for loss, damage or injury, including, without limitation,
                death or personal injury. Some jurisdictions do not allow the
                limitation of liability for personal injury, or of incidental or
                consequential damages, so this limitation may not apply to you.
                In no event will the {BRAND_NAME} total liability to you for all
                damages, losses or causes of action exceed the amount of € 100.
                The foregoing limitations will apply even if the above-stated
                remedy fails of its essential purpose
              </li>
              <li>
                You agree that in the event you incur any damages, losses or
                injuries that arise out of acts of {BRAND_NAME} or omissions,
                the damages, if any, caused to you are not irreparable or
                sufficient to entitle you to an injunction preventing any
                exploitation of any website, service, property, product or other
                content owned or controlled by the {BRAND_NAME}, and you will
                have no rights to enjoin or restrain the development,
                production, distribution, advertising, exhibition or
                exploitation of any website, property, product, service, or
                other content owned or controlled by the {BRAND_NAME}
              </li>
              <li>
                {BRAND_NAME} is not responsible for the actions, content,
                information, or data of third parties, and you release us, our
                directors, officers, employees, and agents from any claims and
                damages, known and unknown, arising out of or in any way
                connected with any claim you have against any such third parties
              </li>
              <li>
                You agree that any claim you may have arising out of or related
                to your relationship with {BRAND_NAME} must be filed within one
                year after such claim arose; otherwise, your claim is
                permanently barred.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Indemnification">
            You agree to defend (at the request of {BRAND_NAME}), indemnify and
            hold the {BRAND_NAME} harmless from and against any claims,
            liabilities, damages, losses, and expenses, including without
            limitation, reasonable attorney&apos;s fees and costs, arising out
            of or in any way connected with any of the following (including as a
            result of your direct activities on the Service or those conducted
            on your behalf):
            <ul>
              <li>your Content or your access to or use of the Service;</li>
              <li>your breach or alleged breach of these Terms;</li>
              <li>
                your violation of any third-party right, including without
                limitation, any intellectual property right, publicity,
                confidentiality, property or privacy right;
              </li>
              <li>
                your violation of any laws, rules, regulations, codes, statutes,
                ordinances or orders of any governmental and quasi-governmental
                authorities, including, without limitation, all regulatory,
                administrative and legislative authorities; or
              </li>
              <li>any misrepresentation made by you</li>
              <li>
                You will cooperate as fully required by {BRAND_NAME} in the
                defence of any claim. {BRAND_NAME} reserves the right to assume
                the exclusive defence and control of any matter subject to
                indemnification by you, and you will not, in any event, settle
                any claim without the prior written consent of {BRAND_NAME}.
              </li>
            </ul>
          </SectionContent>
          <SectionContent title="Partial invalidity">
            If, at any time, any provision of these Terms is or becomes illegal,
            invalid or unenforceable in any respect under any law of any
            jurisdiction, neither the legality, validity or enforceability of
            the remaining provisions nor the legality, validity or
            enforceability of such provision under the law of any other
            jurisdiction will in any way be affected or impaired.
          </SectionContent>
          <SectionContent title="Contact">
            If you have any questions about these Terms and Conditions, please
            send an email to{' '}
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> or reach out
            to us via our <a href={CONTACT_PAGE_PATH}>contact page</a>.
          </SectionContent>
          <div className="spacing-medium"></div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
