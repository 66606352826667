import urlJoin from 'url-join';
import env from '../.env.json';
import { MAIN_SITE_DOMAIN } from '../content/constants';
import { Logger } from './logger';
import * as Sentry from '@sentry/react';

export class SentryLogger implements Logger {
  error(message: string, data?: unknown): void {
    this.logMessage('error', message, data);
  }

  warning(message: string, data?: unknown): void {
    this.logMessage('warning', message, data);
  }

  info(message: string, data?: unknown): void {
    this.logMessage('info', message, data);
  }

  static init() {
    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],

      tracesSampleRate: 0.6,

      tracePropagationTargets: [
        new RegExp(
          `^${urlJoin(MAIN_SITE_DOMAIN, 'api').replace(/([\.\/])/, '\\$1')}`,
        ),
      ],

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  private logMessage = (
    level: Sentry.SeverityLevel,
    message?: string,
    data?: unknown,
  ) =>
    Sentry.withScope((scope) => {
      scope.setLevel(level);

      if (message) {
        Sentry.captureMessage(message);
      }

      if (data) {
        Sentry.captureException(data);
      }
    });
}

export const logger = new SentryLogger();
