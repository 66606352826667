import React from 'react';

import './UpcomingFeaturesSection.css';
import SectionHeading from '../../../SectionHeading/SectionHeading';

export const COMPONENT_ID = 'home-upcoming-features-section';

function UpcomingFeaturesSection() {
  return (
    <div id={COMPONENT_ID} data-testid={COMPONENT_ID}>
      <div className="content-container-large">
        <SectionHeading
          preTitle="Features"
          title="Upcoming features"
          subtitle="We're cooking up new ways to make your social planning even more
          seamless. Get ready for exciting updates."
        />
      </div>
      <div className="content-container-large">
        <div id="home-upcoming-features-section-grid">
          <div>
            <div className="text-large text-bold">Polling</div>
            <div className="spacing-tiny"></div>
            <div className="text-medium">
              Create your activity, create a poll, and let the app do the rest.
              No more lengthy group chats.
            </div>
          </div>
          <div>
            <div className="text-large text-bold">Sync &amp; Schedule</div>
            <div className="spacing-tiny"></div>
            <div className="text-medium">
              Link your calendar to effortlessly coordinate and schedule
              activities. No double bookings, just double the fun.
            </div>
          </div>
          <div>
            <div className="text-large text-bold">Group Payments</div>
            <div className="spacing-tiny"></div>
            <div className="text-medium">
              Host pays first, but collecting from friends is a breeze.
              Automatic reminders ensure everyone chips in.
            </div>
          </div>
          <div>
            <div className="text-large text-bold">Gear Recommendations</div>
            <div className="spacing-tiny"></div>
            <div className="text-medium">
              Discover sporting equipment and gear your friends swear by. Get in
              the game with confidence.
            </div>
          </div>
          <div>
            <div className="text-large text-bold">One-Tap Venue Booking</div>
            <div className="spacing-tiny"></div>
            <div className="text-medium">
              Skip the extra steps. Reserve your favorite locations directly
              through the app and get on with the fun.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingFeaturesSection;
