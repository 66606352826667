import React from 'react';

import appleAppStore from '../../assets/app-stores/apple-app-store-download-link.png';
import googlePlayStore from '../../assets/app-stores/google-play-store-download-link.png';

import './AppStoreLinks.css';
import {
  APPLE_APP_STORE_LINK,
  GOOGLE_PLAY_STORE_LINK,
} from '../../navigation/constants';

const LINK_HEIGHT = 45;

export const COMPONENT_TEST_ID = 'app-store-links';
export type HorizontalAlignment = 'left' | 'center' | 'right';

function AppStoreLinks(props: { alignment: HorizontalAlignment }) {
  return (
    <div
      className={`app-store-links app-store-links-align-${props.alignment}`}
      data-testid={COMPONENT_TEST_ID}
    >
      <a href={APPLE_APP_STORE_LINK} className="app-store-link">
        <img
          src={appleAppStore}
          loading="lazy"
          height={LINK_HEIGHT}
          width=""
          alt="Get it on the App Store"
          className="app-store-link-image"
        />
      </a>
      <a href={GOOGLE_PLAY_STORE_LINK} className="app-store-link">
        <img
          src={googlePlayStore}
          loading="lazy"
          height={LINK_HEIGHT}
          width=""
          alt="Get it on the App Store"
          className="app-store-link-image"
        />
      </a>
    </div>
  );
}

export default AppStoreLinks;
